var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"shadow-box"},[_vm._m(0),_c('span',{staticClass:"note"},[_c('span',[_vm._v("Digital Assessment /")]),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.$t('login_page.title')))])]),_c('div',{staticClass:"form"},[_c('validation-observer',{ref:"observer"},[_c('validation-provider',{attrs:{"name":"ID","mode":"passive","rules":{
              required: true,
              approved: _vm.isLoginSuccess && !_vm.isApproved
            }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('md-field',{class:{'md-invalid': errors[0]}},[_c('label',[_vm._v("ID")]),_c('md-input',{staticClass:"login-input",attrs:{"data-testid":"login-username","type":"text","placeholder":"ID"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"PW","mode":"passive","rules":{
              required_if: 'ID',
              login: !_vm.isLoginSuccess
            }},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('md-field',{class:{'md-invalid': errors[0]}},[_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"data-testid":"login-password","type":"password","placeholder":"Password"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.performLogin.apply(null, arguments)}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"login-button",attrs:{"type":"submit"},on:{"click":_vm.performLogin}},[_vm._v(" "+_vm._s(_vm.$t('login_page.create_my_trial'))+" ")]),_c('span',{staticClass:"create-account",on:{"click":_vm.moveToJoin}},[_vm._v(_vm._s(_vm.$t('login_page.go_to_sally')))])])],1)],1)]),_vm._m(1)]),_c('copyright')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/sally-logo-full.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"poweredby"},[_c('span',[_vm._v("PoweredBy")]),_c('img',{attrs:{"src":require("@/assets/images/sally-logo-full.svg")}})])
}]

export { render, staticRenderFns }