<template>
  <div class="login">
    <div class="wrapper">
      <div class="shadow-box">
        <div class="logo">
          <img src="~@/assets/images/sally-logo-full.svg" />
        </div>
        <span class="note">
          <span>Digital Assessment /</span>
          <span class="bold"> {{ $t('login_page.title') }}</span>
        </span>
        <div class="form">
          <validation-observer ref="observer">
            <validation-provider
              name="ID"
              mode="passive"
              :rules="{
                required: true,
                approved: isLoginSuccess && !isApproved
              }" v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>ID</label>
                <md-input data-testid="login-username" v-model="user.username" type="text"
                  placeholder="ID" class="login-input"></md-input>
                <span class="md-error">{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <validation-provider
              name="PW"
              mode="passive"
              :rules="{
                required_if: 'ID',
                login: !isLoginSuccess
              }"
              v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>Password</label>
                <md-input data-testid="login-password" v-model="user.password" type="password"
                  placeholder="Password" @keyup.enter.native="performLogin"></md-input>
                <span class="md-error">{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <div class="button-group">
              <button class="login-button" type="submit" @click="performLogin">
                {{ $t('login_page.create_my_trial') }}
              </button>
              <span class="create-account" @click="moveToJoin">{{ $t('login_page.go_to_sally') }}</span>
            </div>
          </validation-observer>
        </div>
      </div>
      <div class="poweredby">
        <span>PoweredBy</span>
        <img src="~@/assets/images/sally-logo-full.svg" />
      </div>
    </div>
    <copyright/>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Copyright from '@/components/Common/Copyright.vue';
import bus from '@/common/bus.util';
import { FRONTEND_URL } from '@/common/config';
import { getRole } from '@/common/local-storage';

export default {
  name: 'TrialLogin',
  components: {
    Copyright,
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      isLoginSuccess: true,
      isApproved: true,
    };
  },
  created() {
    bus.$emit('end:spinner');
  },
  methods: {
    ...mapActions(['trialLogin']),
    moveToJoin() {
      window.location.replace(FRONTEND_URL);
    },
    async performLogin() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      const { isLoginSuccess, isApproved } = await this.trialLogin({
        username: this.user.username.trim(),
        password: this.user.password,
      });
      this.isLoginSuccess = isLoginSuccess;
      this.isApproved = isApproved;

      if (getRole() === 'ADMIN') {
        this.$router.push('/admin');
      } else if (this.isLoginSuccess && this.isApproved) {
        this.$router.push('/');
      }
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.isLoginSuccess = true;
        this.isApproved = true;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #fff;
  overflow: auto;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .shadow-box {
      width: 426px;
      height: 488px;
      border-radius: 5px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      .logo {
        display: flex;
        flex-direction: row;
        margin-bottom: 36px;
        width: 120px;
        .text {
          margin-left: 16.5px;
        }
      }
      .note {
        font-size: $font_size_14;
        font-weight: $font_weight_400;
        letter-spacing: normal;
        text-align: center;
        color: #2a324b;
        margin-bottom: 47px;
        .bold {
          font-weight: 500;
        }
      }
      .form {
        width: 100%;
        max-width: 250px;
        .login-input {
          height: 25px;
        }
        .message {
          color: #DE5959;
          font-size: $font_size_11;
        }
        .button-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            margin-top: 9px;
            margin-bottom: 40px;
          }
          .create-account {
            font-size: $font_size_14;
            letter-spacing: normal;
            color: #2a324b;
            cursor: pointer;
            text-decoration: underline;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .poweredby {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: normal;
      color: #2a324b;
      display: flex;
      align-items: flex-end;
      margin-top: 6.5px;
      img {
        width: 37px;
        margin-left: 3px;
      }
    }
  }
  .copyright {
    padding: 0 24px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .login {
    .wrapper {
      padding: 0 24px;
      .shadow-box {
        width: 100%;
        height: 100%;
        box-shadow: none;
      }
      .poweredby {
        display: none;
      }
    }
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/components/md-field.scss';
</style>
